import styled from "styled-components";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from "@auth0/auth0-react";
import { API_BASE } from '../../shared/Constants';


export interface AccountModalProps {
    openModal: boolean;
    setOpenModal: (open: boolean) => void;
}

function AccountModal({ openModal, setOpenModal }: AccountModalProps) {
    const { logout, getAccessTokenSilently } = useAuth0();

    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm("Você tem certeza que deseja apagar sua conta? Esta ação é irreversível.");
        if (confirmDelete) {
            const url = API_BASE + "/api/delete-account";
            const access_token = await getAccessTokenSilently()
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Auth ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                logout({logoutParams: { returnTo: window.location.origin + "/auth" }});
            } else {
                alert("Erro ao apagar conta. Tente novamente mais tarde.");
            }
        }
    };

    return (
        <>
            <StyledModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                BackdropProps={{onClick: (event) => event.stopPropagation()}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContainer>
                    <TitleContainer>
                        <Title>Minha Conta</Title>
                        <CloseButton type="button" onClick={() => setOpenModal(false)}>
                            <CloseIcon/>
                        </CloseButton>
                    </TitleContainer>
                    <Desc>Ao apagar sua conta, todas as chaves de API, histórico de conversas com a Maritalk e créditos na plataforma serão <strong>permanentemente</strong> excluídos.</Desc>
                    <Desc>Para excluir sua conta, clique no botão abaixo.</Desc>
                    <DeleteAccountButton onClick={handleDeleteAccount}>Apagar Conta</DeleteAccountButton>
                </ModalContainer>
            </StyledModal>
        </>
    );
}

export default AccountModal;


const DeleteAccountButton = styled.button`
    background-color:rgb(185, 20, 20);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 8px 35px;
    margin: 10px 0 20px 20px;
    width: calc(90% + 10px);
    font-size: 1.1rem;
    font-weight: 600;
    font-family: Plus Jakarta Sans, sans-serif;
    cursor: pointer;

    &:hover {
        background-color:rgb(167, 31, 31);
    }

    &:active {
        background-color: rgb(185, 20, 20);
    }

    @media (max-width: 1300px) {
        margin-top: 5px;
    }
`

const Desc = styled.p`
    font-size: 1.1rem;
    color: ${props => props.theme.desc_color};
    margin: 0px 0px 10px 22px;
    flex-wrap: wrap;
    max-width: 90%;
    text-align: justify;
`;

const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: ${props => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
    padding: 5px 0px 0px 22px;
`;


const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    width: clamp(0px, 80%, 600px);
    outline: none;
    box-shadow: none;
`;


const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
`;

const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.gray_hover};
    }
`;

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;

     &:focus-visible {
        outline: none;
    }
`;
