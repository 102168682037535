const Theme = {
    primary: '#3D138B',
    secondary: '#194a11',
    backgroundSoftWhite: 'white',
    yellow: '#F4D35E',
    paleYellow: '#faeebe',
    mediumYellow: '#f8de8e',
    red: '#f7605f',
    green: '#70A587',
    orange: '#e8934a',
    
    // sidebar_background: '#f5f5f5',
    // sidebar_hover: '#dfdede',
    // sidebar_selected: '#dfdede',
    // sidebar_font_color: '#252525',
    sidebar_background: 'white',
    sidebar_hover: '#ecebeb',
    sidebar_selected: '#ecebeb',
    sidebar_font_color: '#081241',
    user_message_background: '#dddeec',        
    sidebar_new_chat_color: '#081241',

    edit_button_color: '#0e1b57',
    edit_button_hover: '#2b3977',

    prompt_suggestions_border: '#e5e7eb',
    prompt_suggestions_hover: '#f9f9f9',

  
    black: '#000000',
    buttonTextColor: "#353740",
    white: '#FFFFFF',
  
    grey: '#ececf1',
    slate: '#616161',
    lightGrey: '#E8E8E8',
    darkGrey: '#555555',
    intenseGrey: '#333333',
    borderGrey: "#ececf1",
    borderTextArea: "#a198e6",
    textAreaFont: "helvetica",

    desc_color: "#8f8f8f",
    dark_blue: "#1d2752",

    widgetsLabelFontSize: "0.875rem",
  };
  
  export default Theme;
  